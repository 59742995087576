import cls from 'classnames';
import { FC, useEffect, useRef } from 'react';

import ChannelSwitcherMobile from '@/components/channel-switcher/mobile';
import EventNavbar from '@/components/event-navbar';
// import FloatingPlayer from '@/components/floating-player';
import Footer from '@/components/footer';
import useOutsideClickNotifier from '@/lib/hooks/use-outside-click-notifier';
import { NavigationEntry, ThemeSettings } from '@/types/views/generic';

import ChannelSwitcherDesktopOverlay from '../channel-switcher-desktop-overlay';

interface EventLayoutProps {
  navigation: NavigationEntry;
  isNavOpen: boolean;
  isChannelSwitcherOpen: boolean;
  themeSettings?: ThemeSettings;
  currentBreakpoint: string;
  className?: string;
  onNavToggle: () => void;
  onChannelSwitcherToggle: () => void;
  hasInvertedThemeColor?: boolean;
  deactivateAdSlots?: boolean;
}

const EventLayout: FC<EventLayoutProps> = ({
  navigation,
  themeSettings,
  currentBreakpoint,
  isNavOpen,
  isChannelSwitcherOpen,
  className = '',
  children,
  onNavToggle,
  onChannelSwitcherToggle,
  hasInvertedThemeColor,
  deactivateAdSlots,
}) => {
  const channelSwitchRef = useRef<HTMLDivElement | null>(null);
  const channelSwitchMobileRef = useRef<HTMLDivElement | null>(null);
  const floatingPlayerRef = useRef<HTMLDivElement | null>(null);
  const floatingPlayerMobileRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = () => {
    if (isChannelSwitcherOpen) {
      onChannelSwitcherToggle();
    }
  };

  useOutsideClickNotifier(
    [
      channelSwitchRef,
      channelSwitchMobileRef,
      floatingPlayerRef,
      floatingPlayerMobileRef,
    ],
    handleClickOutside,
  );

  // Close event navigation on transition to greater screen sizes
  useEffect(() => {
    if (isNavOpen && currentBreakpoint.includes('xl')) onNavToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavOpen, currentBreakpoint]);

  return (
    <>
      <EventNavbar
        navigation={navigation}
        themeSettings={themeSettings}
        isNavOpen={isNavOpen}
        className={className}
        onNavToggle={onNavToggle}
      />

      {/*
      Floating Player functionality suspended due to potential legal complications
      related to music playback rights on event websites
      Commented out for potential future reimplementation

      {!isNavOpen && (
        <FloatingPlayer
          floatingPlayerRef={floatingPlayerRef}
          floatingPlayerMobileRef={floatingPlayerMobileRef}
          onToggleChannelSwitcher={onChannelSwitcherToggle}
          isChannelSwitcherOpen={isChannelSwitcherOpen}
        />
      )} */}

      <ChannelSwitcherDesktopOverlay
        channelSwitchRef={channelSwitchRef}
        isChannelSwitcherOpen={isChannelSwitcherOpen}
        onChannelSwitcherToggle={onChannelSwitcherToggle}
      />

      {isChannelSwitcherOpen && (
        <div className="fixed flex flex-col-reverse bottom-0 left-0 w-full z-40 md:hidden h-full bg-black">
          <ChannelSwitcherMobile
            onClose={onChannelSwitcherToggle}
            ref={channelSwitchMobileRef}
          />
        </div>
      )}

      <div className={cls(deactivateAdSlots && 'w-full')}>{children}</div>

      <Footer
        {...navigation}
        isEventTheme
        hasInvertedThemeColor={hasInvertedThemeColor}
      />
    </>
  );
};

export default EventLayout;
